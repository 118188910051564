import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

const data = [
  {
    id: 1,
    date: "11/15/2024",
    amount: 5000,
    payor: "ABC Music",
    description: "Royalty payment",
    bank_account: "Chase Business (...1234)"
  },
  {
    id: 2, 
    date: "11/16/2024",
    amount: 2500,
    payor: "XYZ Entertainment",
    description: "Monthly service fee",
    bank_account: "Bank of America (...5678)"
  },
  {
    id: 3,
    date: "11/18/2024",
    amount: 7500,
    payor: "123 Events",
    description: "Merchandise payment",
    bank_account: "Wells Fargo (...9012)"
  }
]

class DepositList extends Component {
  render() {
    const { userData } = this.props;
    return (
      <table className="w-full text-[15px]">
        <thead>
          <tr className="border-b border-slate-200">
            <th className="py-2">Deposit ID</th>
            <th className="py-2">Date</th>
            <th className="py-2">Amount</th>
            <th className="py-2">Payor</th>
            <th className="py-2">Description</th>
            <th className="py-2">Bank Account</th>
          </tr>
        </thead>
        <tbody>
          {data.map(deposit => (
            <tr key={deposit.id} className="border-t border-slate-200">
              <td className="py-2">
                <NavLink to={`/app/client/${userData?.active_client?.id}/deposits/view/${deposit.id}`} className="no-underline text-blue-700 hover:text-blue-500 font-normal">{deposit.id}</NavLink>
              </td>
              <td className="py-2">{deposit.date}</td>
              <td className="py-2">${deposit.amount.toLocaleString()}</td>
              <td className="py-2">{deposit.payor}</td>
              <td className="py-2">{deposit.description}</td>
              <td className="py-2">{deposit.bank_account}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default DepositList;
