import { Button } from "@headlessui/react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component } from 'react';
import payorsActions from "../../redux/payors/actions";

const { payorsGetAllForClient } = payorsActions;

class PayorList extends Component {

  componentDidMount() {
    const { userData } = this.props;
    this.props.payorsGetAllForClient({
      client_id: userData?.active_client?.id,
      pagination: true,
    });
  }

  formatAddress = (item) => {
    return `address_country} ${item?.address_state}, ${item?.address_city}, ${item?.address_zip}`
  }

  render() {
    const { editPayor, payors } = this.props;

    return (
      <table className="w-full text-[15px]">
        <thead>
          <tr className="border-b border-slate-200">
            <th className="py-2">Name</th>
            <th className="py-2">Address</th>
            <th className="py-2">City</th>
            <th className="py-2">State</th>
            <th className="py-2">Zip</th>
            <th className="py-2">Country</th>
            <th className="py-2">Status</th>
            <th className="py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {payors?.data?.map(payor => (
            <tr key={`payor-${payor.id}`} className="border-t border-slate-200">
              <td className="py-2">{payor.name}</td>
              <td className="py-2">{`${payor?.address_line_1 ? payor?.address_line_1 : ""} ${payor?.address_line_2 ? payor?.address_line_2 : ""}`}</td>
              <td className="py-2">{payor?.address_city ? payor?.address_city : ""}</td>
              <td className="py-2">{payor?.address_state ? payor?.address_state : ""}</td>
              <td className="py-2">{payor.address_zip ? payor.address_zip : ""}</td>
              <td className="py-2">{payor.address_country ? payor.address_country : ""}</td>
              <td className="py-2">
                {payor.is_active
                  ? <span className="font-medium text-green-600">Active</span>
                  : <span className="font-medium text-slate-500">Inactive</span>
                }
              </td>
              <td className="py-2">
                <Button className="btn-secondary btn-sm" onClick={() => editPayor(payor)}>Change</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      payors: state.Payors.get("payors"),
      payors_working: state.Payors.get("payors_working"),
    }),
    {
      payorsGetAllForClient,
    }
  )(PayorList)
);
