import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "@headlessui/react";
import DepositList from "./DepositList";
import UploadedDepositsDocument from "./UploadedDepositsDocument";
import { NavLink } from "react-router-dom";

class Deposits extends Component {
  state = {
    mode: 'list',
  }

  render() {
    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">{this.state.mode === 'list' ? 'Deposits' : 'Record Deposit'}</h1>
          <div className="flex items-center justify-end gap-6">
            {this.state.mode === 'list' &&
              <>
                <NavLink to="deposits/payor">Manage Payors</NavLink>
                <Button className="btn-primary" onClick={() => this.setState({ mode: 'record' })}>Record Deposit</Button>
              </>
            }
            {this.state.mode === 'record' &&
              <Button className="btn-tertiary" onClick={() => this.setState({ mode: 'list' })}>&larr; Back</Button>
            }
          </div>
        </div>
        {this.state.mode === 'list' &&
          <div className="bg-slate-100/75 rounded-lg text-slate-600 p-4 mb-5">
            <DepositList
              userData={this.props.userData}
            />
          </div>
        }
        {this.state.mode === 'record' &&
          <UploadedDepositsDocument />
        }
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {

    }
  )(Deposits)
);
