import axios from "axios";
import defaults from "superagent-defaults";
import { getTokenLocal } from "../helpers/auth/utils";
import { createAxiosResponseInterceptorForClient } from "../helpers/auth/token_refresh";
import { appConfig } from "./app";

const apiEndpoints = {
  auth: {
    login: "/auth/login",
    login_2fa: "/auth/login/2fa",
    token: {
      refresh: "/auth/token/refresh",
    }
  },
  user: {
    user: "/user/",
    password: "/user/password",
    client_switch: "/user/client/switch",
    settings: "/user/settings",
  },
  clients: {
    clients: "/clients",
    client: "/clients/client",
    client_assign: "/clients/client/assign",
    group: "/clients/group",
    groups: "/clients/groups",
    dashboard: {
      clients: {
        activity: "/clients/dashboard/clients/activity",
        tasks: "/clients/dashboard/clients/tasks",
      }
    },
  },
  companies: {
    "users": "/companies/users",
    "cards": "/companies/cards",
  },
  vendors: {
    vendors: "/vendors",
    vendor: "/vendors/vendor",
    vendors_autofill: "/vendors/autofill",
  },
  documents: {
    client: "/documents/client",
    document: "/documents/document",
    document_preview: "/documents/document/preview",
  },
  invoices: {
    invoices: "/invoices",
    invoice: "/invoices/invoice",
    invoice_status: "/invoices/invoice/status",
    invoice_recurring: "/invoices/invoice/recurring",
  },
  accounting: {
    gl_accounts_search: "/accounting/gl/accounts/search",
    gl_1099_all: "/accounting/gl/1099/all",
    gl_accounts: "/accounting/gl/accounts",
    gl_account: "/accounting/gl/account",
  },
  reports: {
    report: "/reports/report",
  },
  statements: {
    statements: "/statements/",
    statements_statement: "/statements/statement",
    statements_statement_entry: "/statements/statement/entry",
  },
  payors: {
    payors: "/payors",
    payor: "/payors/payor",
    payors_autofill: "/payors/autofill",
  },
};

const env =
  process.env.REACT_APP_NODE_ENV || appConfig.environments["production"];

const API_ROOT = appConfig.backendApiUrl[env];

const client = axios.create({ baseURL: API_ROOT });

const superagent = defaults();

const request = {
  init: () =>
    superagent.set(
      "Authorization",
      `Bearer ${getTokenLocal().toJS().access_token}`
    ),
  delete: (url) => request.init().delete(url),
  get: (url) => request.init().get(url),
  post: (url) => request.init().post(url),
  put: (url) => request.init().put(url),
};

client.defaults.headers.common["Authorization"] = `Bearer ${
  getTokenLocal().toJS().access_token
}`;

const interceptor = createAxiosResponseInterceptorForClient(client);

interceptor();

export { request, API_ROOT, client, apiEndpoints };
