import { Map } from "immutable";

const initState = new Map({
  payors: {},
  payors_working: false,
  payors_autofill: {},
  payors_autofill_working: false,
});

export default function payorsReducer(state = initState, action) {
  switch (action.type) {

    case "PAYORS_AUTOFILL_SEARCH":
      return state
        .set("payors_autofill", {})
        .set("payors_autofill_working", true)
    case "PAYORS_AUTOFILL_SEARCH_SUCCESS":
      return state
        .set("payors_autofill", {...action.data.data})
        .set("payors_autofill_working", false)
    case "PAYORS_AUTOFILL_SEARCH_FAILED":
      return state
        .set("payors_autofill", {})
        .set("payors_autofill_working", false)

    case "PAYORS_UPDATE_PAYOR":
      return state
        .set("payors_working", true)
    case "PAYORS_UPDATE_PAYOR_SUCCESS":
      var payors_update = {...state.get("payors")};
      if(!payors_update?.data){ payors_update.data = [] };
      payors_update.data = payors_update.data.map(item => {
        if(item.id === action.data.data.id){
          item = {...action.data.data}
        }
        return item;
      })
      return state
        .set("payors", {...payors_update})
        .set("payors_working", false)
    case "PAYORS_UPDATE_PAYOR_FAILED":
      return state
        .set("payors_working", false)

    case "PAYORS_ADD_PAYOR":
      return state
        .set("payors_working", true)
    case "PAYORS_ADD_PAYOR_SUCCESS":
      var payors_add = {...state.get("payors")}
      if(payors_add?.data){
        payors_add.data = [action.data.data, ...payors_add?.data];
      } else {
        payors_add.data = [action.data.data];
      }
      return state
        .set("payors", {...payors_add})
        .set("payors_working", false)
    case "PAYORS_ADD_PAYOR_FAILED":
      return state
        .set("payors_working", false)

    case "PAYORS_GET_ALL_FOR_CLIENT":
      return state
        .set("payors", {})
        .set("payors_working", true)
    case "PAYORS_GET_ALL_FOR_CLIENT_SUCCESS":
      return state
        .set("payors", {...action.data.data})
        .set("payors_working", false)
    case "PAYORS_GET_ALL_FOR_CLIENT_FAILED":
      return state
        .set("payors", {})
        .set("payors_working", false)

		default:
			return state;
  }
}
