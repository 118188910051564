import React, { Component } from 'react';
import { withRouter, useParams } from "react-router";
import { Button, Field, Label, Textarea, Transition } from "@headlessui/react";
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { ReactComponent as IconDownload } from '../../assets/icons/icon-download.svg'
import { ReactComponent as IconMail } from '../../assets/icons/icon-mail.svg'
import { ReactComponent as IconSlack } from '../../assets/icons/icon-slack.svg'
import { Tooltip } from "react-tooltip"
import PDFViewer from "../../components/PDFViewer";
import printJS from "print-js"
import Select from "react-select";
import DatePicker from "react-datepicker";
import CurrencyInput from "react-currency-input-field";
import QuickAddPayor from "./QuickAddPayor";
import DepositGLTable from "./DepositGLTable";
import Spinner from "../../components/Spinner";

const payor_options = [
  {value: 1, label: 'XYZ Entertainment', address_line_1: '123 Main St', address_line_2: 'Suite 456', address_city: 'Anytown', address_state: 'CA', address_zip: '12345', address_country: 'USA'},
  {value: 'new_payor', label: <span className="text-blue-500">+ Add new payor</span>},
]

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class DepositForm extends Component {
  state = {
    selectedPayor: null
  }

  setDefaultEmptyValue = (field) => {
    return field ? field : '-'
  }

  submit = () => {
    console.log('submit')
  }

  update = () => {
    console.log('update')
  }

  render() {
    const { data, edit, document_preview_url, doc_id, deposits_working } = this.props;
    return (
      <div>
        <div className="flex justify-between items-center mb-5"> 
          <h1 className="text-xl font-semibold">{edit ? `Deposit ${data.id}` : "Record Deposit"}</h1>
          <Button className="btn-tertiary" onClick={() => this.props.history.goBack()}>Cancel</Button>
        </div>
        <div className="flex justify-between">
          <div className="flex-1">
            <Field className="field">
              <Label>Bank</Label>
              <Select />
            </Field>
            <div className="grid grid-cols-2 gap-2 mt-4">
              <Field className="field">
                <Label>Deposit Date</Label>
                <DatePicker
                  // onChange={}
                  // selected={}
                />
              </Field>
              <Field className="field">
                <Label>Clear Date</Label>
                <DatePicker
                  // onChange={}
                  // selected={}
                />
              </Field>
            </div>
            <Field className="field mt-4">
              <Label>Payor</Label>
              <Select
                placeholder="Select payor"
                options={payor_options}
                value={this.state.selectedPayor}
                onChange={selected => {
                  this.setState({ selectedPayor: selected });
                }}
              />
              {this.state.selectedPayor?.value === 'new_payor' ?
              <QuickAddPayor
                saveAndSelect={newPayor => {}}
                cancel={() => this.setState({ selectedPayor: null })}
              />
              :
              <div className="flex-1 mt-4">
                <div className="border rounded">
                  <div className="group px-3 py-2 shadow-none rounded-none text-slate-500 bg-slate-100 text-left w-full flex justify-between items-center">
                    <span>Payor Details</span>
                  </div>
                  <div className="p-3 bg-slate-50/50">
                    <Field className="field">
                      <Label>Address</Label>
                      <span className="block">{this.setDefaultEmptyValue(this.state.selectedPayor?.address_line_1)}</span>
                      <span className="block">{this.setDefaultEmptyValue(this.state.selectedPayor?.address_line_2)}</span>
                    </Field>
                    <div className="grid grid-rows-1 grid-flow-col gap-2 mt-4">
                      <Field className="field">
                        <Label>City</Label>
                        <span>{this.setDefaultEmptyValue(this.state.selectedPayor?.address_city)}</span>
                      </Field>
                      <Field className="field">
                        <Label>State/Province</Label>
                        <span>{this.setDefaultEmptyValue(this.state.selectedPayor?.address_state)}</span>
                      </Field>
                      <Field className="field">
                        <Label>Zip Code</Label>
                        <span>{this.setDefaultEmptyValue(this.state.selectedPayor?.address_zip)}</span>
                      </Field>
                      <Field className="field">
                        <Label>Country</Label>
                        <span>{this.setDefaultEmptyValue(this.state.selectedPayor?.address_country)}</span>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              }
            </Field>
            <Field className="field mt-4">
              <Label>Amount</Label>
              <CurrencyInput
                prefix="$"
              />
            </Field>
            <Field className="field mt-4">
              <Label>Description</Label>
              <Textarea />
            </Field>
          </div>
          <Transition
            show={document_preview_url ? true : false}
            enter="transition-transform duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
          >
            <div className="pl-6">
              <div className="block mb-1 flex justify-between items-center">
                <div>
                  <span className="text-slate-500">Document ID:</span> <span>{edit && data ? data?.document : doc_id}</span>
                </div>
                <div className="flex gap-3 text-slate-500 text-sm">
                  <span
                    data-tooltip-id="t-print" className="flex gap-1" role="button"
                    onClick={() => printJS(document_preview_url)}
                  >
                    <IconPrint className="size-4" />
                  </span>
                  <span data-tooltip-id="t-download" className="flex gap-1" role="button">
                    <IconDownload className="size-4" />
                  </span>
                  <span data-tooltip-id="t-mail" className="flex gap-1" role="button">
                    <IconMail className="size-4" />
                  </span>
                  <span data-tooltip-id="t-slack" className="flex gap-1" role="button">
                    <IconSlack className="size-4" />
                  </span>
                  <Tooltip id="t-print" content="Print PDF" />
                  <Tooltip id="t-download" content="Download PDF" />
                  <Tooltip id="t-mail" content="Mail PDF" />
                  <Tooltip id="t-slack" content="Share on Slack" />
                </div>
              </div>
              <PDFViewer
                file_url={document_preview_url}
                height={580}
              />
            </div>
          </Transition>
        </div>
        <div className="mt-8">
          <h2 className="font-semibold text-lg mb-2">Accounting</h2>
          <DepositGLTable
            data={[]}
            // addRow={}
            // deleteRow={}
          />
        </div>
        <div className="flex justify-between mt-8 bg-slate-50 p-2">
          {this.props.data
            ?
              <Button
                className="btn-primary"
                onClick={this.update}
              >
                <span className="flex gap-1 justify-center items-center">
                  {deposits_working && <Spinner />}
                  <span>Update</span>
                </span>
              </Button>
            :
              <Button
                className="btn-primary"
                onClick={this.submit}
              >
                <span className="flex gap-1 justify-center items-center">
                  {deposits_working && <Spinner />}
                  <span>Submit</span>
                </span>
              </Button>
          }
          <Button
            className="btn-tertiary"
            onClick={() => this.props.history.goBack()}
          >Cancel</Button>
        </div>
      </div>
    );
  }
}

export default withParams(withRouter(DepositForm));
