import { Button } from "@headlessui/react";
import React, { Component } from 'react';
import PayorList from "./PayorList";
import PayorForm from "./PayorForm";
import { withRouter } from "react-router";

class ManagePayor extends Component {
  state = {
    mode: 'list',
    edit_payor_data: null,
  }

  render() {
    const { mode, edit_payor_data } = this.state;
    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">
            {mode === 'list' ? 'Payors' : mode === 'add' ? 'Add New Payor' : `Edit Payor ${edit_payor_data?.id}`}
          </h1>
          <div className="flex items-center justify-end gap-4">
            {mode === 'list' &&
            <>
              <Button className="btn-tertiary" onClick={() => this.props.history.goBack()}>&larr; Back</Button>
              <Button className="btn-primary" onClick={() => this.setState({ mode: 'add' })}>Add New Payor</Button>
            </>
              
            }
            {(mode === 'add' || mode === 'edit') &&
              <Button className="btn-tertiary" onClick={() => this.setState({ mode: 'list' })}>&larr; Back</Button>
            }
          </div>
      </div>
      {mode === 'list' &&
      <div className="bg-slate-100/75 rounded-lg text-slate-600 p-4 mb-5">
        <PayorList
          editPayor={payor => this.setState({ mode: 'edit', edit_payor_data: payor })}
        />
      </div>
      }
      {mode === 'add' &&
      <PayorForm />
      }
      {mode === 'edit' &&
      <PayorForm data={edit_payor_data} />
      }
      </div>
    )
  }
}

export default withRouter(ManagePayor);
