import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component, useCallback, useState } from 'react'
import { useDropzone } from "react-dropzone"
import Spinner from "../components/Spinner"

const MAX_FILESIZE_NUMERIC = 5
const MAX_FILESIZE_BYTES = MAX_FILESIZE_NUMERIC * 1048576

function MultiFilesUpload({ uploadOnDrop, uploading }) {
  const [filesWithError, setFilesWithError] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setFilesWithError([])
    if(acceptedFiles.length > 0) {
      uploadOnDrop(acceptedFiles)
    }

    if(rejectedFiles.length > 0) {
      const files = []
      rejectedFiles.forEach(file => {
        files.push(file)
      })
      setFilesWithError(files)
    }
  }, [setFilesWithError, uploadOnDrop])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'],
      'application/x-ofx': ['.ofx'],
      'application/vnd.intu.qfx' : ['.qfx'],
      'text/csv': ['.csv']
    },
    maxSize: MAX_FILESIZE_BYTES,
    onDrop
  })

  return (
    <div
      {...getRootProps()}
      className="border bg-slate-50 rounded-md px-5 flex flex-col items-center justify-center text-center cursor-pointer min-h-[330px]"
    >
      <input {...getInputProps()} />
      {uploading ?
      <div className="flex items-center gap-1">
        <Spinner fillColorClass="fill-slate-500" />
        <span>Uploading 1 of 2...</span>
      </div>
      :
      <>
        {filesWithError.map((filesWithError, idx) => (
          <div className="py-2">
            <p>{filesWithError.file.name}</p>
            {filesWithError.errors.map((error, errorIdx) =>
            <p key={`file-${idx}-error-$${errorIdx}`} className="text-red-500 text-sm">{error.message}</p>
            )}
          </div>
        ))}
        <div className="text-slate-500">
          <p>Drag and drop your files here.</p>
          <p>Max size: {MAX_FILESIZE_NUMERIC} MB</p>
        </div>
      </>
      }
    </div>
  )
}

class MassUploadDocument extends Component {
  state = {
    selectedFiles: [],
    uploading: false
  }

  processUpload = (files) => {
    this.setState({ uploading: true })
    setTimeout(() => {
      this.setState({ uploading: false })
      this.props.onUploadDone();
    }, 1500)
  }

  render() {
    //const { userData, documents_working } = this.props;

    return (
      <MultiFilesUpload
        uploadOnDrop={this.processUpload} // upload on files drop
        uploading={this.state.uploading}
      />
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      //documents_working: state.Documents.get("documents_working"),
    }),
    {

    }
  )(MassUploadDocument)
);
