import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* payorsGetAllForClient() {
  yield commonSaga(
    actions.PAYORS_GET_ALL_FOR_CLIENT,
    apiCalls.get,
    apiEndpoints.payors.payors,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* payorsAddPayor() {
  yield commonSaga(
    actions.PAYORS_ADD_PAYOR,
    apiCalls.post,
    apiEndpoints.payors.payor,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* payorsUpdatePayor() {
  yield commonSaga(
    actions.PAYORS_UPDATE_PAYOR,
    apiCalls.put,
    apiEndpoints.payors.payor,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* payorsAutofillSearch() {
  yield commonSaga(
    actions.PAYORS_AUTOFILL_SEARCH,
    apiCalls.post,
    apiEndpoints.payors.payors_autofill,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* payorsSaga() {
	yield all([
		fork(payorsGetAllForClient),
		fork(payorsAddPayor),
		fork(payorsUpdatePayor),
		fork(payorsAutofillSearch),
	]);
}
