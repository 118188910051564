import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useParams } from "react-router"
import DepositForm from './DepositForm';
import documentsActions from "../../redux/documents/actions";

const {
  documentsDocumentPreview,
} = documentsActions;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ViewDeposit extends Component {
  
  render() {
    return (
      <DepositForm 
        data={{
          id: this.props.params.id,
        }}
        edit={true}
        document_preview_url="/client_invoice.pdf"
      />
    );
  }
}

export default withParams(withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      // document_preview_url: state.Documents.get("document_preview_url"),
      // document_preview_url_working: state.Documents.get("document_preview_url_working"),
    }),
    {
      documentsDocumentPreview,
    }
  )(ViewDeposit)
));
