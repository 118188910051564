import React, { Component } from 'react'
import SelectGLAccount from "../../components/SelectGLAccount"
import Select from "react-select"
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'
import { Button, Input } from "@headlessui/react"

class PayorAccounts extends Component {
  render() {
    const {
      data,
      addRow,
      deleteRow,
    } = this.props

    return (
      <table className="w-full text-[15px] table-auto">
      <thead className="border-b-2 border-slate-200">
        <tr>
            <th className="p-1">#</th>
            <th className="p-1">Account</th>
            <th className="p-1">Division</th>
            <th className="p-1">Description</th>
            <th className="p-1">Actions</th>
          </tr>
        </thead>
        <tbody>
        {data.length > 0 && data.map((item, idx) => (
          <tr key={`row-${idx}`} id={`id-${item.id}`}>
            <td className="px-1 py-2 max-w-6">{idx+1}.</td>
            <td className="px-1 py-2">
              <div className="w-44">
                <SelectGLAccount
                  menuPlacement="top"
                  //value={item.account}
                  //onChange={(e) => this.props.updateRowData(item.id, "account", e)}
                />
              </div>
            </td>
            <td className="px-1 py-2">
              <Select />
            </td>
            <td className="px-1 py-2">
              <Input />
            </td>
            <td className="px-1 py-2">
            <span
                role="button"
                onClick={() => deleteRow(item.id)}
              >
                <IconTrash className="text-red-500 size-4" />
              </span>
            </td>
          </tr>
        ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="flex justify-between pt-4">
                <Button
                  className="btn-tertiary btn-sm"
                  onClick={addRow}
                  //disabled={data.length > 0 && !this.checkDataValid()}
                >+ Add</Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }
}

export default PayorAccounts