const payorsActions = {
	PAYORS_GET_ALL_FOR_CLIENT: 'PAYORS_GET_ALL_FOR_CLIENT',
	PAYORS_ADD_PAYOR: 'PAYORS_ADD_PAYOR',
	PAYORS_UPDATE_PAYOR: 'PAYORS_UPDATE_PAYOR',
	PAYORS_AUTOFILL_SEARCH: 'PAYORS_AUTOFILL_SEARCH',

  payorsAutofillSearch: (data) => ({
    type: payorsActions.PAYORS_AUTOFILL_SEARCH,
    data
  }),

  payorsUpdatePayor: (data) => ({
    type: payorsActions.PAYORS_UPDATE_PAYOR,
    data
  }),

  payorsAddPayor: (data) => ({
    type: payorsActions.PAYORS_ADD_PAYOR,
    data
  }),

  payorsGetAllForClient: (data) => ({
    type: payorsActions.PAYORS_GET_ALL_FOR_CLIENT,
    data
  }),
}

export default payorsActions;
