import React, { Component } from 'react';
import DepositForm from "./DepositForm";
import { useParams } from "react-router";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class RecordDeposit extends Component {
  render() {
    return (
      <DepositForm
        doc_id={this.props.params.doc_id}
        document_preview_url="/client_invoice.pdf"
      />
    )
  }
}

export default withParams(RecordDeposit);