import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./user/saga";
import clientsSagas from "./clients/saga";
import companiesSagas from "./companies/saga";
import vendorsSagas from "./vendors/saga";
import documentsSagas from "./documents/saga";
import invoicesSagas from "./invoices/saga";
import accountingSagas from "./accounting/saga";
import reportsSagas from "./reports/saga";
import statementsSagas from "./statements/saga";
import payorsSagas from "./payors/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    clientsSagas(),
    companiesSagas(),
    vendorsSagas(),
    documentsSagas(),
    invoicesSagas(),
    accountingSagas(),
    reportsSagas(),
    statementsSagas(),
    payorsSagas(),
  ]);
}
