import React, { Component } from 'react'
import { Tab, TabGroup, TabList, TabPanels, TabPanel, Field, Label, Input, Button } from "@headlessui/react"
import { CountrySelect, StateSelect } from 'react-country-state-city'
import Spinner from "../../components/Spinner"
import PayorAccounts from "./PayorAccounts"

class QuickAddPayor extends Component {
  state = {
    new_payor_data: null,
    accounts: [],
  }

  setPayorData = (key, value) => {
    const { new_payor_data } = this.state;
    if(new_payor_data){
      var new_payor = {...new_payor_data};
      new_payor[key] = value;
      if(key === "address_country_data"){
        new_payor["address_country"] = value.name;
      }
      if(key === "address_state_data"){
        new_payor["address_state"] = value.name;
      }
      this.setState({new_payor_data: new_payor})
    } else {
      this.setState({new_payor_data: {[key]: value}})
      if(key === "address_country_data"){
        this.setState({address_country: value.name});
      }
      if(key === "address_state_data"){
        this.setState({address_state: value.name});
      }
    }
  }

  render() {
    const { saveAndSelect, cancel } = this.props
    return (
      <div className="p-3 bg-slate-50 mt-4 rounded">
        <TabGroup>
          <TabList>
            <Tab>General</Tab>
            <Tab>Accounts</Tab>
          </TabList>
          <TabPanels className="mt-4">
            <TabPanel>
              <Field className="field">
                <Label>Payor Name</Label>
                <Input
                  type="text"
                  required
                  onChange={(e) => this.setPayorData("name", e.target.value)} 
                />
              </Field>
              <div className="mt-4">
                <Field className="field">
                  <Label>Address Line 1</Label>
                  <Input
                    type="text"
                    required
                    onChange={(e) => this.setPayorData("address_line_1", e.target.value)}
                  />
                </Field>
                <Field className="field mt-4">
                  <Label>Address Line 2</Label>
                  <Input
                    type="text"
                    onChange={(e) => this.setPayorData("address_line_2", e.target.value)}
                  />
                </Field>
                <div className="flex gap-4 mt-4">
                  <Field className="field flex-1">
                    <Label>Country</Label>
                    <CountrySelect
                      placeHolder="Select country"
                      showFlag={false}
                      onChange={e => this.setPayorData("address_country_data", e)}
                    />
                  </Field>
                  <Field className="field flex-1">
                    <Label>State/Province</Label>
                    <StateSelect
                      placeHolder="Select state/province"
                      countryid={this.state.new_payor_data?.address_country_data?.id}
                      onChange={e => this.setPayorData("address_state_data", e)}
                    />
                  </Field>
                </div>
                <div className="flex gap-4 mt-4">
                  <Field className="field flex-1">
                    <Label>City</Label>
                    <Input
                      type="text"
                      onChange={(e) => this.setPayorData("address_city", e.target.value)}
                    />
                  </Field>
                  <Field className="field flex-1">
                    <Label>Zip Code</Label>
                    <Input
                      type="text"
                      className="max-w-36"
                      onChange={(e) => this.setPayorData("address_zip", e.target.value)}
                    />
                  </Field>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <PayorAccounts
                data={[]}
                // addRow={}
                // deleteRow={}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div className="flex justify-between mt-6">
          <Button
            className="btn-secondary"
            disabled={this.state.payors_working && !this.state.new_payor_data}
            onClick={() => {
              this.setState({ payors_working: true })
              setTimeout(() => this.setState({ payors_working: false }), 1000)
              // save payor to db, then auto select new payor on Select dropdown
              saveAndSelect({id: 99, ...this.state.new_payor_data})
            }}
          >
            <span className="flex gap-1 justify-center items-center">
              {this.state.payors_working && <Spinner fillColorClass="fill-blue-500" />}
              <span>Save and select payor</span>
            </span>
          </Button>
          <Button className="btn-tertiary" onClick={cancel}>Cancel</Button>
        </div>
      </div>
    )
  }
}

export default QuickAddPayor;